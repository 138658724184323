import React from "react"

export const TitleUnderlineCovertionsOne = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="122" height="12" viewBox="0 0 122 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.5591 10.1295C19.6621 9.37465 1.83412 11.3923 0.563057 11.2449C-0.564224 10.9866 0.251035 5.11195 1.13119 4.19046C1.44325 3.86294 1.84922 3.7761 2.81854 3.86582C3.66869 3.94365 9.53054 3.48279 23.7113 2.45895C23.9885 2.62817 24.6921 1.99037 24.8577 2.20357C24.9841 2.3272 27.0434 2.27846 30.1048 2.16888C33.5398 2.04486 42.8914 1.62225 47.5033 1.45522C54.3099 1.20119 51.7498 1.24685 59.5419 1.0324C61.1719 0.9866 59.8977 1.35845 61.8145 1.20274C65.9027 0.876139 74.8367 1.14334 77.9309 0.849707C80.4127 0.613721 80.9534 0.600293 83.3369 0.832739C85.2437 1.01758 98.7027 1.0223 100.223 0.674302C100.59 0.570466 100.868 0.622426 100.859 0.750799C101.018 0.762945 109.975 1.15872 110.014 1.26505C110.077 1.39387 110.346 1.46811 110.609 1.38597C111.087 1.24374 120.826 1.18646 120.959 1.56697C121.334 2.6637 119.66 7.15392 118.485 8.22435C117.254 9.34469 111.222 7.76084 106.035 8.30402C106.035 8.30402 104.573 8.2671 102.379 8.20334C80.9579 7.62471 81.6873 7.93631 77.5991 8.2685C77.0212 7.87407 74.8291 8.82099 73.4739 8.14817C72.9111 7.8711 68.9284 7.84657 68.0364 8.12026C67.8452 8.18051 66.4301 8.19413 64.371 8.1982C62.32 8.20232 59.625 8.19131 56.898 8.23035C55.5305 8.24985 54.1551 8.26372 52.8512 8.29477C51.5393 8.3202 50.2909 8.37393 49.1855 8.42855C46.9668 8.53772 45.3124 8.6783 44.8023 8.88734C40.7594 8.31523 26.4249 9.76249 22.5591 10.1295Z" fill="#FFBA00"/>
      </svg>      
  </span>
)

export const TitleUnderlineCovertionsTwo = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="192" height="12" viewBox="0 0 192 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M35.6123 10.2099C31.0356 9.4447 2.89477 11.3988 0.887403 11.2469C-0.893349 10.9846 0.372897 5.11271 1.75926 4.19434C2.25079 3.86792 2.89146 3.78252 4.42222 3.87571C5.76477 3.95656 15.0182 3.51659 37.4042 2.54328C37.8425 2.7135 38.951 2.07819 39.2132 2.29199C39.4133 2.41606 42.6645 2.37467 47.4976 2.276C52.9207 2.16422 67.6841 1.77494 74.9651 1.62434C85.7109 1.39458 81.669 1.43111 93.971 1.24443C96.5443 1.20444 94.5338 1.57176 97.5597 1.42288C104.013 1.11085 118.12 1.4099 123.004 1.12729C126.922 0.900148 127.775 0.888647 131.539 1.1296C134.551 1.32124 155.801 1.37394 158.2 1.03135C158.778 0.928822 159.217 0.981773 159.204 1.11012C159.455 1.12283 173.598 1.55055 173.66 1.65702C173.76 1.78606 174.186 1.86127 174.601 1.78007C175.355 1.63953 190.731 1.61697 190.942 1.99797C191.538 3.09606 188.912 7.58041 187.06 8.64667C185.12 9.76265 175.591 8.15725 167.404 8.68196C167.404 8.68196 165.095 8.63982 161.631 8.56824C127.807 7.91324 128.96 8.22744 122.507 8.54506C121.593 8.14857 118.135 9.08769 115.993 8.41003C115.104 8.13094 108.815 8.09221 107.408 8.36274C107.106 8.4223 104.872 8.43088 101.621 8.42761C98.3827 8.42442 94.1277 8.4038 89.8222 8.43312C87.6632 8.44774 85.4917 8.45671 83.433 8.48312C81.3618 8.50386 79.3909 8.55315 77.6459 8.60382C74.1433 8.70509 71.5316 8.83977 70.727 9.047C64.3417 8.46047 41.7147 9.85666 35.6123 10.2099Z" fill="#FFBA00"/>
      </svg>      
  </span>
)
