import React from "react"

export const TitleUnderlineLeftOne = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="190" height="8" viewBox="0 0 190 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M35.324 8C31.3958 7.25735 2.63283 8.08646 0.542167 7.86265C-1.16466 7.56254 1.563 2.27751 3.14733 1.49925C3.70266 1.21949 4.34783 1.17881 5.82599 1.30597C8.16982 1.54505 38.4763 1.07198 38.4763 1.07198C38.8765 1.23475 40.077 0.685396 40.2975 0.883775C40.9916 1.34666 90.1141 0.715913 94.3036 0.69048C96.8434 0.67522 94.8344 0.99059 97.8234 0.863424C104.193 0.609091 118.077 0.853252 122.903 0.563314C126.766 0.324241 127.599 0.314075 131.307 0.507368C135.096 0.680314 155.243 0.431066 157.546 0.0597408C158.11 -0.0419921 158.543 -0.00638251 158.543 0.11061C158.543 0.136044 172.744 0.222513 172.81 0.314073C172.916 0.431066 173.349 0.487015 173.749 0.405629C174.492 0.283549 189.608 -0.15899 189.845 0.181815C190.588 1.15845 188.506 5.31932 186.75 6.33157C184.953 7.41502 175.088 6.19932 167.158 6.85041L130.237 6.91654L122.323 7.32347C121.417 6.97249 117.93 7.85756 115.823 7.22682C114.933 6.97757 108.644 6.94196 107.256 7.20647C106.047 7.40994 73.8788 6.89618 70.5794 7.60323C64.6994 7.01318 40.8528 7.85757 35.324 8Z" fill="#FFA700" />
    </svg>
  </span>
)

export const TitleUnderlineLeftTwo = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="242" height="8" viewBox="0 0 242 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M44.9916 8C39.9883 7.25735 3.3534 8.08646 0.690549 7.86265C-1.48342 7.56254 1.99077 2.27751 4.00871 1.49925C4.71602 1.21949 5.53776 1.17881 7.42048 1.30597C10.4058 1.54505 49.0066 1.07198 49.0066 1.07198C49.5163 1.23475 51.0454 0.685396 51.3262 0.883775C52.2104 1.34666 114.777 0.715913 120.113 0.69048C123.348 0.67522 120.789 0.99059 124.596 0.863424C132.709 0.609091 150.392 0.853252 156.54 0.563314C161.46 0.324241 162.521 0.314075 167.243 0.507368C172.07 0.680314 197.731 0.431066 200.664 0.0597408C201.382 -0.0419921 201.933 -0.00638251 201.933 0.11061C201.933 0.136044 220.022 0.222513 220.105 0.314073C220.24 0.431066 220.791 0.487015 221.301 0.405629C222.248 0.283549 241.501 -0.15899 241.803 0.181815C242.75 1.15845 240.097 5.31932 237.861 6.33157C235.572 7.41502 223.007 6.19932 212.907 6.85041L165.881 6.91654L155.801 7.32347C154.647 6.97249 150.205 7.85756 147.522 7.22682C146.388 6.97757 138.378 6.94196 136.61 7.20647C135.071 7.40994 94.0982 6.89618 89.8959 7.60323C82.4067 7.01318 52.0336 7.85757 44.9916 8Z" fill="#FFA700" />
    </svg>
  </span>
)

