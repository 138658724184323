import React, { useEffect, useRef } from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import useNearScreen from "@hooks/useNearScreen"
import imageOneDesktop from "@components/pageIndustrias/pageLogistica/images/imageExperience/imagesDesktop/imageOne.webp"
import imageTwoDesktop from "@components/pageIndustrias/pageLogistica/images/imageExperience/imagesDesktop/imageTwo.webp"
import imageThreeDesktop from "@components/pageIndustrias/pageLogistica/images/imageExperience/imagesDesktop/imageThree.webp"
import imageFourDesktop from "@components/pageIndustrias/pageLogistica/images/imageExperience/imagesDesktop/imageFour.webp"

import imageOneMobile from "@components/pageIndustrias/pageLogistica/images/imageExperience/imagesMobile/imageOne.webp"
import imageTwoMobile from "@components/pageIndustrias/pageLogistica/images/imageExperience/imagesMobile/imageTwo.webp"
import imageThreeMobile from "@components/pageIndustrias/pageLogistica/images/imageExperience/imagesMobile/imageThree.webp"
import imageFourMobile from "@components/pageIndustrias/pageLogistica/images/imageExperience/imagesMobile/imageFour.webp"


const Experience = ({ title }) => {
  const { isNearScreen, fromRef } = useNearScreen({ distance: "10px" })
  const sliderRef = useRef()

  useEffect(() => {
    if (isNearScreen) sliderRef.current.slickPlay()
  }, [isNearScreen])

  const activateSliderTestimonies = {
    arrows: true,
    autoplay: false,
    // autoplay: true,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplaySpeed: 14000,
  }

  const data = [
    {
      title: "Garantiza la atención durante los picos de demanda",
      description: "Escala tu atención añadiendo o quitando agentes sin costos adicionales de infraestructura. Utiliza herramientas de autoservicio con IA para manejar consultas básicas eficientemente y enruta la atención que realmente importa a un agente.",
      image: imageOneMobile,
      background: "#EEEAFB"
    },
    {
      title: "Envía notificaciones masivas",
      description: "Distribuye notificaciones masivas a clientes y empleados vía SMS, email y WhatsApp. Ideal para alertas de servicio, actualizaciones de envío y anuncios importantes.",
      image: imageTwoMobile,
      background: "#FCEEEE"
    },
    {

      title: "Reduce formularios abandonados",
      description: "Envía mensajes de seguimiento por WhatsApp a quienes no completaron un formulario de envío. Facilita el proceso y aumenta la tasa de finalización.",
      image: imageThreeMobile,

      background: "#EEEAFB"
    },
    {
      title: "Comunicación bidireccional en tiempo real",
      description: "Facilita la comunicación en tiempo real entre clientes y agentes a través de múltiples canales (chat, email, teléfono). Permite respuestas rápidas y resoluciones eficientes.",
      image: imageFourMobile,
      background: "#FCEEEE"
    },
  ]

  return (
    <section className="container-experience-ecommerce">
     {title}

      {/* Contenedor de dos columnas */}
      <div className="container-experience-ecommerce-cardsOne">
        {/* una sola fila */}
        <section className="container-experience-ecommerce-cardsOne-card" style={{ background: "#EEEAFB", padding: "32px 32px 0px" }}>
          <section >
            <p className="container-experience-ecommerce-cardsOne-card-title">Garantiza la atención durante los picos de demanda</p>
            <p className="container-experience-ecommerce-cardsOne-card-description">
              Escala tu atención añadiendo o quitando agentes sin costos adicionales de infraestructura. Utiliza herramientas de autoservicio con IA para manejar consultas básicas eficientemente y enruta la atención que realmente importa a un agente.
            </p>
            <br />
          </section>
          <section>
            <img
              className="container-experience-ecommerce-cardsOne-card-image"
              src={imageOneDesktop}
              alt="foto"
              loading="lazy"
            />
          </section>
        </section>
        {/* Dos filas */}
        <div className="container-experience-ecommerce-cardsOne-column">
          {/* columna */}
          <section className="container-experience-ecommerce-cardsOne-card" style={{ background: "#FCEEEE", padding: "32px 32px 20px" }}>
            <section>
              <p className="container-experience-ecommerce-cardsOne-card-title">Envía notificaciones masivas</p>
              <p className="container-experience-ecommerce-cardsOne-card-description">
                Distribuye notificaciones masivas a clientes y empleados vía SMS, email y WhatsApp. Ideal para alertas de servicio, actualizaciones de envío y anuncios importantes.
              </p>
              <br />
            </section>
            <section>
              <img
                className="container-experience-ecommerce-cardsOne-card-image"
                src={imageTwoDesktop}
                alt="foto"
                loading="lazy"
              />
            </section>
          </section>
        </div>
      </div>

      {/* Contenedor de dos columnas */}
      <div className="container-experience-ecommerce-cardsTwo">
        {/* una sola fila */}
        <section className="container-experience-ecommerce-cardsTwo-card" style={{ background: "#FCEEEE", padding: "32px 32px 0px" }}>
          <section >
            <p className="container-experience-ecommerce-cardsTwo-card-title">Reduce formularios abandonados</p>
            <p className="container-experience-ecommerce-cardsTwo-card-description">
              Envía mensajes de seguimiento por WhatsApp a quienes no completaron un formulario de envío. Facilita el proceso y aumenta la tasa de finalización.
            </p>
            <br />
          </section>
          <section>
            <img
              className="container-experience-ecommerce-cardsTwo-card-image"
              src={imageThreeDesktop}
              alt="foto"
              loading="lazy"
            />
          </section>
        </section>
        {/* Dos filas */}
        <div className="container-experience-ecommerce-cardsTwo-column">
          {/* columna */}
          <section className="container-experience-ecommerce-cardsTwo-card" style={{ background: "#EEEAFB", padding: "32px 32px 0px" }}>
            <section>
              <p className="container-experience-ecommerce-cardsTwo-card-title">Comunicación bidireccional en tiempo real</p>
              <p className="container-experience-ecommerce-cardsTwo-card-description">
                Facilita la comunicación en tiempo real entre clientes y agentes a través de múltiples canales (chat, email, teléfono). Permite respuestas rápidas y resoluciones eficientes.
              </p>
              <br />
            </section>
            <section>
              <img
                className="container-experience-ecommerce-cardsTwo-card-image"
                src={imageFourDesktop}
                alt="foto"
                loading="lazy"
              />
            </section>
          </section>
        </div>
      </div>


      <div className="container-experience-ecommerce-cards-mobile" ref={fromRef}>
        <Slider {...activateSliderTestimonies} ref={sliderRef}>
          {data.map((elemento, i) => {
            return (
              <section key={i}>
                <div className="container-experience-ecommerce-cards-mobile-card" style={{ background: elemento.background }}>
                  <section>
                    <p className="container-experience-ecommerce-cards-mobile-card-title">{elemento.title}</p>
                    <p className="container-experience-ecommerce-cards-mobile-card-description">
                      {elemento.description}
                    </p>
                    <br />
                  </section>
                  <section>
                    <img
                      className="container-experience-ecommerce-cards-mobile-card-image"
                      src={elemento.image}
                      alt="foto"
                      loading="lazy"
                    />
                  </section>
                </div>
              </section>
            )
          })}
        </Slider>
      </div>
    </section>
  )
}
export default Experience
